var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.car)?_c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('div',{staticClass:"h-stack h-space-between"},[_c('a',{staticClass:"heading-title-2"},[_vm._v(_vm._s(_vm.car.name))]),(_vm.$store.state.accessLevel == 10)?_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.$router.push('/lifecars/car/edit/' + _vm.id)}}},[_vm._v(" Edit ")]):_vm._e()]),_c('div',{staticClass:"card light mn"},[_c('div',{staticClass:"content h-stretch"},[_c('div',{staticClass:"v-stack gap-3 h-stretch"},[_c('div',{staticClass:"pane-horizontal gap-3"},[_c('label',{staticClass:"text-right"},[_vm._v("Fuel Consumption per 100km:")]),_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.car.consumption || "Not Set"))])]),_c('div',{staticClass:"pane-horizontal gap-3"},[_c('label',{staticClass:"text-right"},[_vm._v("Depriciation value CZK per km:")]),_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.car.useRatePerKm || "Not Set"))])])])])]),_c('div',{staticClass:"h-stack h-space-between"},[_c('a',{staticClass:"heading-title-2"},[_vm._v("Trips")]),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/lifecars/car/trip/add/' + _vm.id)}}},[_vm._v(" Borrow ")])]),_c('Table',{attrs:{"items":_vm.getDays(),"css":'80px 80px 40px 80px 1fr 80px',"columns":[
  {
    name: 'From',
    sort: 'date',
    path: ['startDate'],
    formatType: 'date',
    format: 'DD.MM.YYYY',
  },
  {
    name: 'To',
    sort: 'date',
    path: ['endDate'],
    formatType: 'date',
    format: 'DD.MM.YYYY',
  },
  {
    name: 'Km',
    sort: 'numeric',
    path: ['km'],
  },
  {
    name: 'Price',
    sort: 'numeric',
    path: ['price'],
    formatType: 'currency',
    format: 'czk',
  },
  {
    name: 'Description',
    sort: 'alphabet',
    path: ['description'],
  },
  {
    name: 'State',
    sort: 'alphabet',
    path: ['submitted'],
    formatMap: function (submitted) { return (submitted ? 'Submitted' : 'Reserved'); },
  } ]},on:{"rowSelected":function($event){!$event.submitted
        ? _vm.$router.push('/lifecars/car/trip/edit/' + $event._id)
        : null}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }