<template>
  <div class="v-stack h-stretch gap-3" v-if="car">
    <div class="h-stack h-space-between">
      <a class="heading-title-2">{{ car.name }}</a>
      <button v-if="$store.state.accessLevel == 10" class="edit" @click="$router.push('/lifecars/car/edit/' + id)">
        Edit
      </button>
    </div>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Fuel Consumption per 100km:</label>
            <div class="text-left">{{ car.consumption || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Depriciation value CZK per km:</label>
            <div class="text-left">{{ car.useRatePerKm || "Not Set" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-stack h-space-between">
      <a class="heading-title-2">Trips</a>
      <button class="add" @click="$router.push('/lifecars/car/trip/add/' + id)">
        Borrow
      </button>
    </div>
    <Table :items="getDays()" :css="'80px 80px 40px 80px 1fr 80px'" v-on:rowSelected="
      !$event.submitted
        ? $router.push('/lifecars/car/trip/edit/' + $event._id)
        : null
    " :columns="[
  {
    name: 'From',
    sort: 'date',
    path: ['startDate'],
    formatType: 'date',
    format: 'DD.MM.YYYY',
  },
  {
    name: 'To',
    sort: 'date',
    path: ['endDate'],
    formatType: 'date',
    format: 'DD.MM.YYYY',
  },
  {
    name: 'Km',
    sort: 'numeric',
    path: ['km'],
  },
  {
    name: 'Price',
    sort: 'numeric',
    path: ['price'],
    formatType: 'currency',
    format: 'czk',
  },
  {
    name: 'Description',
    sort: 'alphabet',
    path: ['description'],
  },
  {
    name: 'State',
    sort: 'alphabet',
    path: ['submitted'],
    formatMap: (submitted) => (submitted ? 'Submitted' : 'Reserved'),
  },
]"></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import utils from "@/utils";

export default {
  props: ["id"],
  components: {
    Table,
  },
  data() {
    return {
      car: null,
      gasPrice: 0,
    };
  },
  methods: {
    ...mapActions(["getCar", "getGasPrice"]),
    getDays() {
      if (this.car) {
        if (this.$store.state.accessLevel < 10) {
          return this.car.rentalDays.filter(
            (item) => item.user == this.$store.state.id
          );
        }
        return this.car.rentalDays;
      }
      return [];
    },
  },
  mounted() {
    this.getCar(this.id)
      .then((car) => {
        this.car = car;
        for (const trip of this.car.rentalDays) {
          if (trip.price == null) {
            trip.price = utils.carRentalObject.price(
              this.car,
              trip,
              this.gasPrice
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.getGasPrice().then((gasPrice) => {
      if (gasPrice) {
        this.gasPrice = gasPrice.price;
      }
    }).catch((error) => {
      console.log(error)
    });
  },
};
</script>
